<template>
  <div class="home">
    <div class="jumbotron text-center">
      <b>관리자 메인</b>
      <span> 준비 중입니다.</span>
    </div>
  </div>
</template>

<script>

import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageAdminHome";
  this.initialize = initialize;
}

export default {
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
};
</script>

<style lang="scss" scoped>

</style>